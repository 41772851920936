import PropTypes from 'prop-types'

import {BasicButton} from '../../../../../shared_components/ButtonTypes'

const SplitHousehold = props => (
  <div className='split-household'>
    <BasicButton data-testid='split-household' loading={props.processing} onClick={props.splitHousehold}>
      Split Household
    </BasicButton>
    <ul>
      <li>The household account will be converted to two individual Everplans.</li>
      <li>{props.firstNames} will see everything from the household plan that was marked as either belonging to them, or belonging jointly to them.</li>
      <li>They will no longer be able to access data associated with the other person.</li>
      <li>Any confirmed Deputies that had been added to the plan will be transferred to the individual plan. Pending invitations will not be transferred.</li>
      <li>You will continue to sponsor {props.firstNames}'s Everplans.</li>
    </ul>
  </div>
)

SplitHousehold.propTypes = {
  firstNames: PropTypes.string,
  processing: PropTypes.bool,
  splitHousehold: PropTypes.func
}

export default SplitHousehold
