import {useState, useMemo} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {updateResource, readEndpoint} from 'redux-json-api'

import {allOwnersActiveAndAlive} from '../../../../../lib/clientsEverplanStatus'
import {currentUserConfig} from '../../../../../lib/userTools'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {removedClientName, removedClientVerb} from '../../../../../lib/removeClientsTools'
import routerUtils from '../../../../../lib/routerUtils'

import SplitHouseholdModal from './SplitHouseholdModal'

const SplitHouseholdController = props => {
  const [processing, setProcessing] = useState(false)
  const activeAlive = useMemo(() => allOwnersActiveAndAlive(props), [props.clientSeats, props.owners])
  const {alwaysNotify} = useNotificationContext()

  const splitHousehold = () => {
    setProcessing(true)

    props.updateResource({
      type: 'client-households',
      id: props.firmOwnership.get('id'),
      attributes: {}
    }).then(() => {
      props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
      const refreshClientSeats = props.clientSeats.map(clientSeat => props.readEndpoint(`client-seats/${clientSeat.get('id')}?include=everplan-summary`)).toJS()

      // Refresh client seat information since they now have different everplan so the household detail page has everything it needs
      Promise.all(refreshClientSeats).then(seats => {
        const refreshEverplans = seats.map(seatResponse => {
          const everplanId = seatResponse.body.data.attributes['everplan-id']

          return props.readEndpoint(
            `everplans/${everplanId}?include=firm-ownership.secondary-everplan-owner,request-item-permissions-invite,client-seats`
          )
        })

        return Promise.all(refreshEverplans)
      }).then(() => {
        alwaysNotify.longSuccess(`${removedClientName(props)} ${removedClientVerb(props)} been split.`)
        setProcessing(false)
        routerUtils.push('pro/clients')
      })
    }).catch(() => {
      setProcessing(false)
      alwaysNotify.longError(`${removedClientName(props)} split failed. Please contact your account manager.`)
    })
  }

  if (activeAlive && props.everplan.get('is-household')) {
    return (
      <SplitHouseholdModal
        clientSeats={props.clientSeats}
        processing={processing}
        splitHousehold={splitHousehold}
        buttonText='Split Household'
        disabled={processing}
      />
    )
  } else {
    return null
  }
}

SplitHouseholdController.propTypes = {
  clientSeats: PropTypes.instanceOf(List),
  everplan: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map),
  owners: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => ({userConfig: currentUserConfig(api)})

export default connect(mapStateToProps, {readEndpoint, updateResource})(SplitHouseholdController)
