import classnames from 'classnames'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import {ControlButton} from '../../../../../shared_components/ButtonTypes'
import Modals from '../../../../../shared_components/core/modals/Modals'
import SplitHousehold from './SplitHousehold'

import './splitHouseholdModal.scss'

const SplitHouseholdModal = props => {
  const firstNames = props.clientSeats.map(clientSeat => clientSeat.get('first-name')).join(' and ')

  return (
    <Modals.ButtonPopUpModalLarge
      launcher={
        <ControlButton
          data-testid='launcher'
          className={classnames('split-household-button', {disabled: props.disabled})}
          buttonText={props.buttonText}
          faClass='fa-unlink'
          disabled={props.disabled}
        />
      }>
      <h2>Split {firstNames}'s Everplan? </h2>
      <SplitHousehold firstNames={firstNames} splitHousehold={props.splitHousehold} processing={props.processing} />
    </Modals.ButtonPopUpModalLarge>
  )
}

SplitHouseholdModal.propTypes = {
  buttonText: PropTypes.string,
  clientSeats: PropTypes.instanceOf(List),
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  splitHousehold: PropTypes.func
}

export default SplitHouseholdModal
